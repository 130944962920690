import React, { useEffect, useMemo, useRef, useState } from "react";

import "./css/global.css";
import "./css/animations.css";

import ReCAPTCHA from "react-google-recaptcha";

import Header from "./components/Header";
import Footer from "./components/Footer";
import { states } from "./utils/states";
import api from "./services/api";
import {
  expireDate,
  normalizeCardNumber,
  normalizeCep,
  normalizeCpf,
  normalizeCurrency,
  normalizeNumber,
  normalizePhone,
} from "./utils/normalize";
import axios from "axios";

function App(): JSX.Element {
  const reRef = useRef(null as any);

  const [loading, setLoading] = useState(false);

  const [linkError, setLinkError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);

  const [linkData, setLinkData] = useState({
    id: "",
    value: 0,
    formatedValue: "",
    title: "",
    description: "",
    max_installments: 0,
  });

  const [customerData, setCustomerData] = useState({
    name: "",
    documentNumber: "",
    email: "",
    phone: "",
  });

  const [addressData, setAddressData] = useState({
    zipcode: "",
    street: "",
    number: "",
    complement: "",
    district: "",
    city: "",
    state: "",
  });

  const numberInput = useRef(null as any);

  const [paymentData, setPaymentData] = useState({
    cardNumber: "",
    cardCvv: "",
    cardExpireDate: "",
    cardHoldName: "",
    cardHolderDocumentNumber: "",
    cardInstallments: 0,
  });

  useEffect(() => {
    setLoading(true);
    const uid = window.location.pathname?.replace("/", "");
    if (typeof uid === "undefined" || uid?.length === 0) {
      setLinkError("Nenhum link de pagamento informado");
      setLoading(false);
    } else {
      api
        .get(`/customer-payment-links/${uid}`)
        .then((res) => {
          setLinkData({
            ...res.data,
            formatedValue: normalizeCurrency(res.data.value),
          });
        })
        .catch((err) => {
          if (err?.response?.status === 500) {
            setLinkError(
              "Ocorreu um erro em nossos servidores, tente novamente mais tarde"
            );
          } else if (err?.response?.status === 502) {
            setLinkError("Sistema fora do ar, tente novamente em instantes.");
          } else if (typeof err?.response?.data?.message === "string") {
            setLinkError(err?.response?.data?.message);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  async function handleCep(): Promise<void> {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `https://viacep.com.br/ws/${normalizeNumber(addressData.zipcode)}/json`
      );
      if (data.erro === true) {
        setAddressData({
          ...addressData,
          street: "",
          number: "",
          complement: "",
          district: "",
          city: "",
          state: "",
        });
      } else {
        setAddressData({
          ...addressData,
          street: data.logradouro,
          number: "",
          complement: "",
          district: data.bairro,
          city: data.localidade,
          state: data.uf,
        });
        numberInput?.current?.focus();
      }
    } catch (err) {
      setAddressData({
        ...addressData,
        street: "",
        number: "",
        complement: "",
        district: "",
        city: "",
        state: "",
      });
    }
    setLoading(false);
  }

  async function handleSubmit(): Promise<void> {
    setLoading(true);
    try {
      const token = await reRef?.current?.executeAsync();
      reRef?.current?.reset();
      if (typeof token === "undefined") {
        setErrorMessage("Desafio de captcha não realizado, tente novamente");
        return;
      }

      const uid = window.location.pathname?.replace("/", "");

      const { data } = await api.post(`/customer-payment-links/${uid}`, {
        gResponse: token,
        customerData: {
          ...customerData,
          documentNumber: normalizeNumber(customerData.documentNumber),
          phone: normalizeNumber(customerData.phone),
        },
        addressData: {
          ...addressData,
          zipcode: normalizeNumber(addressData.zipcode),
        },
        paymentData: {
          ...paymentData,
          cardNumber: normalizeNumber(paymentData.cardNumber),
          cardHolderDocumentNumber: normalizeNumber(
            paymentData.cardHolderDocumentNumber
          ),
          type: "credit",
        },
      });
      if (data.completed === true) {
        setSuccess(true);
      }
    } catch (err) {
      if ((err as any)?.response?.status === 500) {
        setErrorMessage(
          "Ocorreu um erro em nossos servidores, tente novamente mais tarde"
        );
      } else if ((err as any)?.response?.status === 502) {
        setErrorMessage("Sistema fora do ar, tente novamente em instantes.");
      } else if (typeof (err as any)?.response?.data?.message === "string") {
        setErrorMessage((err as any)?.response?.data?.message);
      } else if (Array.isArray((err as any)?.response?.data)) {
        setErrorMessage(
          (err as any)?.response?.data?.map((d: any) => d.message).join(";")
        );
      }
    }
    setLoading(false);
  }

  const installments = useMemo(() => {
    const arr: Array<{ label: string; value: number }> = [];
    for (let i = 1; i <= linkData.max_installments; i += 1) {
      if (linkData.value / i >= 100) {
        // TEM QUE SER MAIOR QUE R$ 1,00
        const value = Math.round(linkData.value / i);
        arr.push({
          label: `${i}x de ${normalizeCurrency(value)}`,
          value: i,
        });
      }
    }
    return arr;
  }, [linkData.max_installments, linkData.value]);

  if (linkError?.length > 0) {
    return (
      <div>
        <Header />
        <main className="container">
          <h2 className="text-primary">Validação</h2>
          <p>{linkError}</p>
        </main>
        <Footer />
      </div>
    );
  }

  return (
    <div
      style={loading ? { overflow: "hidden", maxHeight: "100vh" } : undefined}
    >
      {loading ? (
        <div className="loading-screen">
          <img
            src="https://www.lojasimperio.com.br/images/logo-footer.png"
            alt="Logo Império"
            className="float"
          />
          <h2 className="float">Carregando informações</h2>
          <br />
          <div className="lds-dual-ring"></div>
        </div>
      ) : null}
      <Header />
      <main className="container">
        <section>
          <h2 className="text-primary">
            {linkData.title?.length > 0 ? linkData.title : "SEM TÍTULO"}
          </h2>
          <p>
            {linkData?.description?.length > 0
              ? linkData.description
              : "SEM DESCRIÇÃO"}
          </p>
          {!success ? (
            <h3 className="text-primary">
              {linkData.formatedValue} em até {linkData.max_installments}x
            </h3>
          ) : null}
        </section>
        <br />
        <section>
          {success ? (
            <div>
              <h2 className="text-primary">Pagamento realizado com sucesso!</h2>
              <p>Agradecemos sua preferência!</p>
            </div>
          ) : (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <div className="row">
                <fieldset className="col-5">
                  <h4>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-person"
                      viewBox="0 0 16 16"
                    >
                      {" "}
                      <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />{" "}
                    </svg>{" "}
                    Dados do Cliente
                  </h4>
                  <div className="row">
                    <div className="col-12">
                      <label htmlFor="name">Nome Completo</label>
                      <input
                        type="text"
                        id="name"
                        value={customerData.name}
                        onChange={(e) => {
                          setCustomerData({
                            ...customerData,
                            name: e.target.value,
                          });
                        }}
                        required
                      />
                    </div>
                    <div className="col-6">
                      <label htmlFor="documentNumber">CPF</label>
                      <input
                        type="text"
                        inputMode="numeric"
                        id="documentNumber"
                        value={customerData.documentNumber}
                        onChange={(e) => {
                          setCustomerData({
                            ...customerData,
                            documentNumber: normalizeCpf(e.target.value),
                          });
                        }}
                        required
                      />
                    </div>
                    <div className="col-6">
                      <label htmlFor="phone">Telefone</label>
                      <input
                        inputMode="tel"
                        id="phone"
                        value={customerData.phone}
                        onChange={(e) => {
                          setCustomerData({
                            ...customerData,
                            phone: normalizePhone(e.target.value),
                          });
                        }}
                        required
                      />
                    </div>
                    <div className="col-12">
                      <label htmlFor="email">E-mail</label>
                      <input
                        type="email"
                        inputMode="email"
                        id="email"
                        value={customerData.email}
                        onChange={(e) => {
                          setCustomerData({
                            ...customerData,
                            email: e.target.value,
                          });
                        }}
                        required
                      />
                    </div>
                  </div>
                </fieldset>
                <fieldset className="col-7">
                  <h4>
                    <img
                      src="https://www.lojasimperio.com.br/images/introduct1.png"
                      alt="Caminhão de entrega"
                    />{" "}
                    Dados de Endereço
                  </h4>
                  <div className="row">
                    <div className="col-4">
                      <label htmlFor="zipcode">CEP</label>
                      <input
                        type="text"
                        inputMode="numeric"
                        id="zipcode"
                        value={addressData.zipcode}
                        onChange={(e) => {
                          setAddressData({
                            ...addressData,
                            zipcode: normalizeCep(e.target.value),
                          });
                        }}
                        onBlur={() => {
                          handleCep();
                        }}
                        required
                      />
                    </div>
                    <div className="col-8">
                      <label htmlFor="street">Rua</label>
                      <input
                        type="text"
                        id="street"
                        value={addressData.street}
                        onChange={(e) => {
                          setAddressData({
                            ...addressData,
                            street: e.target.value,
                          });
                        }}
                        required
                      />
                    </div>
                    <div className="col-4">
                      <label htmlFor="number">Número</label>
                      <input
                        type="text"
                        id="number"
                        value={addressData.number}
                        onChange={(e) => {
                          setAddressData({
                            ...addressData,
                            number: e.target.value,
                          });
                        }}
                        ref={numberInput}
                        required
                      />
                    </div>
                    <div className="col-4">
                      <label htmlFor="complement">Complemento</label>
                      <input
                        type="text"
                        id="complement"
                        value={addressData.complement}
                        onChange={(e) => {
                          setAddressData({
                            ...addressData,
                            complement: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="col-4">
                      <label htmlFor="district">Bairro</label>
                      <input
                        type="text"
                        id="district"
                        value={addressData.district}
                        onChange={(e) => {
                          setAddressData({
                            ...addressData,
                            district: e.target.value,
                          });
                        }}
                        required
                      />
                    </div>
                    <div className="col-4">
                      <label htmlFor="city">Cidade</label>
                      <input
                        type="text"
                        id="city"
                        value={addressData.city}
                        onChange={(e) => {
                          setAddressData({
                            ...addressData,
                            city: e.target.value,
                          });
                        }}
                        required
                      />
                    </div>
                    <div className="col-4">
                      <label htmlFor="state">Estado</label>
                      <select
                        id="state"
                        value={addressData.state}
                        onChange={(e) => {
                          setAddressData({
                            ...addressData,
                            state: e.target.value,
                          });
                        }}
                        required
                      >
                        <option value="">Selecionar</option>
                        {states.map((state) => (
                          <option value={state.uf} key={state.desc}>
                            {state.desc}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </fieldset>
                <fieldset className="col-12">
                  <h4>
                    <img
                      src="https://www.lojasimperio.com.br/images/introduct2.png"
                      alt="Mão segurando cartão"
                    />{" "}
                    Pagamento em Cartão de Crédito
                  </h4>
                  {errorMessage?.length > 0 ? (
                    <div className="alert-danger">{errorMessage}</div>
                  ) : null}
                  <div className="row">
                    <div className="col-12">
                      <label htmlFor="cardNumber">Número do Cartão</label>
                      <input
                        type="text"
                        inputMode="numeric"
                        id="cardNumber"
                        value={paymentData.cardNumber}
                        onChange={(e) => {
                          setPaymentData({
                            ...paymentData,
                            cardNumber: normalizeCardNumber(e.target.value),
                          });
                        }}
                        required
                      />
                    </div>
                    <div className="col-12">
                      <label htmlFor="cald_hold_name">Nome no Cartão</label>
                      <input
                        type="text"
                        id="cardHoldName"
                        value={paymentData.cardHoldName}
                        onChange={(e) => {
                          setPaymentData({
                            ...paymentData,
                            cardHoldName: e.target.value,
                          });
                        }}
                        required
                      />
                      <small className="text-muted">
                        Digite exatamente como está no cartão
                      </small>
                    </div>
                    <div className="col-4">
                      <label htmlFor="cardHolderDocumentNumber">
                        CPF do Titular
                      </label>
                      <input
                        type="text"
                        inputMode="numeric"
                        id="cardHolderDocumentNumber"
                        value={paymentData.cardHolderDocumentNumber}
                        onChange={(e) => {
                          setPaymentData({
                            ...paymentData,
                            cardHolderDocumentNumber: normalizeCpf(
                              e.target.value
                            ),
                          });
                        }}
                        required
                      />
                    </div>
                    <div className="col-4">
                      <label htmlFor="cardExpireDate">Vencimento</label>
                      <input
                        type="text"
                        inputMode="numeric"
                        id="cardExpireDate"
                        placeholder="MM/AA"
                        value={paymentData.cardExpireDate}
                        onChange={(e) => {
                          setPaymentData({
                            ...paymentData,
                            cardExpireDate: expireDate(e.target.value),
                          });
                        }}
                        required
                      />
                    </div>
                    <div className="col-4">
                      <label htmlFor="cardCvv">CVV</label>
                      <input
                        type="text"
                        inputMode="numeric"
                        id="cardCvv"
                        value={paymentData.cardCvv}
                        onChange={(e) => {
                          setPaymentData({
                            ...paymentData,
                            cardCvv: normalizeNumber(e.target.value)?.slice(
                              0,
                              4
                            ),
                          });
                        }}
                        required
                      />
                    </div>
                    <div className="col-12">
                      <label htmlFor="cardInstallments">Parcelamento</label>
                      <select
                        id="cardInstallments"
                        value={paymentData.cardInstallments}
                        onChange={(e) => {
                          setPaymentData({
                            ...paymentData,
                            cardInstallments: Number(e.target.value),
                          });
                        }}
                        required
                      >
                        <option value="">Selecionar</option>
                        {installments.map((installment) => (
                          <option
                            value={installment.value}
                            key={installment.value}
                          >
                            {installment.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </fieldset>
              </div>

              <ReCAPTCHA
                sitekey="6Lf8NtUaAAAAAEJyuqWiJAo9K4-y_2KqBfP6A_VM"
                size="invisible"
                ref={reRef}
              />
              <button type="submit">Finalizar compra</button>
            </form>
          )}
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default App;
