import React from "react";

const Header = (): JSX.Element => (
  <header>
    <div className="container">
      <h1>
        <a href="#">
          <img
            src="https://www.lojasimperio.com.br/images/logo-imperio.png"
            alt="Logo Lojas Império"
          />
          Lojas Império
        </a>
      </h1>
      <span>Links de Pagamento</span>
    </div>
  </header>
);

export default Header;
