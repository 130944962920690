export const normalizeCurrency = (value: any): string => {
  if (typeof value === "undefined") return "";

  const onlyNums = normalizeNumber(value);

  return new Intl.NumberFormat("pt-BR", {
    currency: "BRL",
    style: "currency",
  }).format(parseFloat((Number(onlyNums) / 100).toFixed(2)));
};

export const normalizeCep = (value: any): string => {
  if (typeof value === "undefined") return "";

  const onlyNums = normalizeNumber(value);

  if (onlyNums.length < 5) {
    return onlyNums;
  }

  return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5, 8)}`;
};

export const normalizePhone = (value: string): string => {
  if (typeof value === "undefined") {
    return "";
  }

  const onlyNums = value.replace(/[^\d]/g, "");

  if (onlyNums.length === 0) {
    return "";
  }

  if (onlyNums.length <= 2) {
    return `(${onlyNums.slice(0, 2)}`;
  }

  if (onlyNums.length <= 6 && onlyNums.length > 2) {
    return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 6)}`;
  }

  if (onlyNums.length > 6 && onlyNums.length < 11) {
    return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 6)}-${onlyNums.slice(
      6,
      10
    )}`;
  }

  return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 7)}-${onlyNums.slice(
    7,
    11
  )}`;
};

export const expireDate = (value: string): string => {
  if (value?.length === 0) {
    return "";
  }

  const onlyNums = value.replace(/[^\d]/g, "");

  if (onlyNums.length <= 2) {
    return onlyNums;
  }

  return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}`;
};

export const normalizeCardNumber = (value: string): string => {
  if (value?.length === 0) {
    return "";
  }

  const onlyNums = value.replace(/[^\d]/g, "");
  if (onlyNums.length <= 5) {
    return onlyNums;
  }

  if (onlyNums.length <= 4) {
    return onlyNums;
  }

  if (onlyNums.length <= 8) {
    return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4, 8)}`;
  }

  if (onlyNums.length <= 12) {
    return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4, 8)} ${onlyNums.slice(
      8,
      12
    )}`;
  }

  return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4, 8)} ${onlyNums.slice(
    8,
    12
  )} ${onlyNums.slice(12, 16)}`;
};

export const normalizeCpf = (value: string): string => {
  if (typeof value === "undefined") {
    return "";
  }

  const onlyNums = value.replace(/[^\d]/g, "");
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}`;
  }
  if (onlyNums.length <= 9) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(
      6,
      9
    )}`;
  }
  return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(
    6,
    9
  )}-${onlyNums.slice(9, 11)}`;
};

export const normalizeNumber = (value: any): string => {
  if (typeof value === "undefined") return "";

  const onlyNums = String(value).replace(/\D/g, "");

  return onlyNums;
};
