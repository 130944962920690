import React from "react";

const Footer = (): JSX.Element => {
  return (
    <footer>
      <div className="container">
        <h5>Dúvidas? Entre em contato com nosso atendimento.</h5>
        <div>
          <a href="tel:+5508131984848">(81) 3198-4848</a>
          <span> ou </span>
          <a href="mailto:aloimperio@lojasimperio.com.br">
            aloimperio@lojasimperio.com.br
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
